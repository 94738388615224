import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { CreateNetDto } from "models/NetModels";
import * as moment from "moment";
import { AuthHttpClient } from "services/auth-http-client";
import { BaseApiService } from "services/base-api-service";
import { saveAs } from "file-saver";
import { FileAttachmentModels } from "models/FileAttachmentModels";
import { NetInfo, ValidationMessage } from "models";
import { NetlogHttpClient } from "lib/http";

@autoinject
export class NetService extends BaseApiService<Models.Net> {
  constructor(
    httpClient: AuthHttpClient,
    private netlogClient: NetlogHttpClient,
    private i18n: I18N,
  ) {
    super(httpClient, Models.Net);
  }

  public createNet(netData: CreateNetDto): Promise<Models.Net> {
    return this.post(netData as any, "/create-net");
  }

  public copyNet(copydata: any): Promise<Models.Net> {
    return this.post(copydata as any, "/copy-net");
  }

  public recalculateData(netId: number): Promise<any> {
    return this.post(null, "/recalculate-data/" + netId);
  }

  public sendNetToServiceStationAquacom(
    netId: number,
    serviceStationId: number,
  ): Promise<any> {
    return this.post(
      null,
      `/send-net-to-service-station-aquacom/${netId}/${serviceStationId}`,
    );
  }

  public approveNet(netId: number): Promise<Models.Net> {
    return this.post(
      null,
      "/approve/" + netId + `?culture=${this.getLocale()}`,
    );
  }

  public unlockNet(netId: number): Promise<Models.Net> {
    return this.post(null, "/unlock/" + netId);
  }

  public updateImportantComment(
    netId: number,
    comment: string,
  ): Promise<Models.Net> {
    return this.post(
      <any> { ImportantComment: comment },
      "/update-important-comment/" + netId,
    );
  }

  public checkNetIdentifiers(netIdentifiers: Array<string>): Promise<any> {
    return this.post(netIdentifiers as any, "/check-net-identifiers");
  }

  public checkCanChangeCustomer(netId, newCustomerId): Promise<any> {
    return this.get(`check-can-change-customer/${netId}/${newCustomerId}`);
  }

  public checkCanChangeCustomerNetId(netId, newCustomerNetId): Promise<any> {
    return this.get(
      `check-can-change-customer-net-id/${netId}/${newCustomerNetId}`,
    );
  }

  public deleteNet(netId: number): Promise<any> {
    return this.delete(null, "/delete-net/" + netId);
  }

  public getNetInfo(netId: number): Promise<Models.Net> {
    return this.get("get-net-info/" + netId);
  }

  public async getNetDetails(netId: number) {
    return (await this.netlogClient.get({
      url: Models.Net.ApiUrl + "/get-net-info/" + netId,
      model: NetInfo,
    })).data
  }

  public validateNetBeforeApproval(
    netId: number,
  ): Promise<ValidationMessage[] | null> {
    return this.get("validate-before-approval/" + netId) as unknown as Promise<
      ValidationMessage[] | null
    >;
  }

  public getAquacomFilesForNet(netId: number): Promise<Array<any>> {
    return this.get("get-aquacom-files-for-net/" + netId) as Promise<any>;
  }

  public regenerateCertificate(netId: number): Promise<any> {
    return this.post(
      null,
      "/regenerate-certificate/" + netId + `?culture=${this.getLocale()}`,
    );
  }

  public getStorageView(
    serviceStationId: number,
    customerIds: string[] = null,
    storageIds: string[] = null,
    showSpecialProducts = null,
    onlyNetsWithoutActiveService = null,
  ): Promise<Models.StorageViewPipeline[]> {
    return this.get(
      `storage-view?serviceStationId=${serviceStationId}&customerIds=${customerIds}&storageIds=${storageIds}&showSpecialProducts=${showSpecialProducts}&onlyNetsWithoutActiveService=${onlyNetsWithoutActiveService}`,
    ) as Promise<any>;
  }

  public updateStorageView(
    netId: number,
    serviceStationStorageId: number,
    sortIndex: number = null,
  ): Promise<any> {
    return this.put(
      { sortIndex, serviceStationStorageId } as any,
      `storage-view/${netId}`,
    ) as Promise<any>;
  }

  public updateStorageViewDetails(netId: number, data: any): Promise<any> {
    return this.put(data as any, `storage-view-details/${netId}`) as Promise<
      any
    >;
  }

  public getStorageViewItemDetails(netId: number): Promise<any> {
    return this.get(`storage-view-details/${netId}`) as Promise<any>;
  }

  public downloadAquacomFile(
    netId: number,
    attachmentId: number,
  ): Promise<any> {
    return this.httpClient
      .fetch(this.apiUrl + `/download-aquacom-file/${netId}/${attachmentId}`, {
        method: "GET",
        headers: {},
      })
      .then((response) => {
        if (response.status < 400) {
          let filename = "";
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return response.blob().then((blob) => {
            saveAs(blob, filename);
          });
        } else {
          return response.text().then((responseText) => {
            throw new Error(
              "An unexpected server error occurred: " + responseText,
            );
          });
        }
      });
  }

  public getList(filters: any): Promise<any> {
    let queryString = this.getFilterStringFromFilter(filters);

    if (filters.orderBy) {
      queryString += "&orderBy=" + filters.orderBy;
    }
    if (filters.orderByDirection) {
      queryString += "&orderByDirection=" + filters.orderByDirection;
    }

    if (filters._select) {
      queryString += "&select=" + filters._select;
    }

    if (filters._export) {
      const _visibleFieldsParams = new URLSearchParams();
      if (filters._visibleFields) {
        _visibleFieldsParams.set("visibleFields", filters._visibleFields);
      }

      if (filters._exportOnlyVisibleFields) {
        _visibleFieldsParams.set(
          "exportOnlyVisibleFields",
          filters._exportOnlyVisibleFields,
        );
      }

      queryString += "&export=" + filters._export;
      queryString += "&" + _visibleFieldsParams.toString();

      return this.getBlob("getNets" + queryString);
    } else {
      // dont use paging if exporting
      if (filters.top) {
        queryString += "&top=" + filters.top;
      }
      if (filters.skip) {
        queryString += "&skip=" + filters.skip;
      }

      return this.getResponse("getNets" + queryString);
    }
  }

  public exportDetailedReport(filters: any): Promise<any> {
    let queryString: string = this.getFilterStringFromFilter(filters);
    return this.getBlob("GetDetailedReport" + queryString);
  }

  private getFilterStringFromFilter(filters): string {
    let queryString: string = "";
    if (filters.netStatusIds) {
      queryString += "?netStatusIds=" + filters.netStatusIds;
    } else {
      queryString += "?";
    }
    if (filters.netTypeIds != null && filters.netTypeIds != undefined) {
      queryString += "&netTypeIds=" + filters.netTypeIds;
    }
    if (filters.netShapeIds != null && filters.netShapeIds != undefined) {
      queryString += "&netShapeIds=" + filters.netShapeIds;
    }
    if (
      filters.specialProductTypeIds != null &&
      filters.specialProductTypeIds != undefined
    ) {
      queryString += "&specialProductTypeIds=" + filters.specialProductTypeIds;
    }
    if (
      filters.specialProductsOnly != null &&
      filters.specialProductsOnly != undefined
    ) {
      queryString += "&specialProductsOnly=" + filters.specialProductsOnly;
    }
    if (
      filters.hasPlannedDeliveryDate != null &&
      filters.specialProductsOnly != undefined
    ) {
      queryString += "&hasPlannedDeliveryDate=" +
        filters.hasPlannedDeliveryDate;
    }
    if (filters.customerId) {
      queryString += "&customerId=" + filters.customerId;
    }
    if (filters.customerIds != null && filters.customerIds != undefined) {
      queryString += "&customerIds=" + filters.customerIds;
    }
    if (filters.responsibleUserId) {
      queryString += "&responsibleUserId=" + filters.responsibleUserId;
    }
    if (filters.circumferenceFrom) {
      queryString += "&circumferenceFrom=" + filters.circumferenceFrom;
    }
    if (filters.circumferenceTo) {
      queryString += "&circumferenceTo=" + filters.circumferenceTo;
    }
    if (filters.circumferenceMeasuredFrom) {
      queryString += "&circumferenceMeasuredFrom=" +
        filters.circumferenceMeasuredFrom;
    }
    if (filters.circumferenceMeasuredTo) {
      queryString += "&circumferenceMeasuredTo=" +
        filters.circumferenceMeasuredTo;
    }
    if (filters.depthFrom) {
      queryString += "&depthFrom=" + filters.depthFrom;
    }
    if (filters.depthTo) {
      queryString += "&depthTo=" + filters.depthTo;
    }
    if (filters.meshsizeFrom) {
      queryString += "&meshsizeFrom=" + filters.meshsizeFrom;
    }
    if (filters.meshsizeTo) {
      queryString += "&meshsizeTo=" + filters.meshsizeTo;
    }
    if (filters.validToFrom) {
      queryString += "&validToFrom=" + filters.validToFrom;
    }
    if (filters.validToTo) {
      queryString += "&validToTo=" + filters.validToTo;
    }
    if (filters.producedFrom) {
      queryString += "&producedFrom=" + filters.producedFrom;
    }
    if (filters.producedTo) {
      queryString += "&producedTo=" + filters.producedTo;
    }
    if (filters.deliveredRecyclingCompanyFrom) {
      queryString += "&deliveredRecyclingCompanyFrom=" +
        filters.deliveredRecyclingCompanyFrom;
    }
    if (filters.deliveredRecyclingCompanyTo) {
      queryString += "&deliveredRecyclingCompanyTo=" +
        filters.deliveredRecyclingCompanyTo;
    }
    if (filters.recyclingCompanyId) {
      queryString += "&recyclingCompanyId=" + filters.recyclingCompanyId;
    }
    if (filters.producerId) {
      queryString += "&producerId=" + filters.producerId;
    }
    if (filters.dimensionClassId) {
      queryString += "&dimensionClassId=" + filters.dimensionClassId;
    }
    if (filters.analysisId) {
      queryString += "&analysisId=" + filters.analysisId;
    }
    if (filters.analysisRef) {
      queryString += "&analysisRef=" + filters.analysisRef;
    }
    if (filters.specialProductCircumferenceFrom) {
      queryString += "&specialProductCircumferenceFrom=" +
        filters.specialProductCircumferenceFrom;
    }
    if (filters.specialProductCircumferenceTo) {
      queryString += "&specialProductCircumferenceTo=" +
        filters.specialProductCircumferenceTo;
    }

    if (filters.specialProductLengthFrom) {
      queryString += "&specialProductLengthFrom=" +
        filters.specialProductLengthFrom;
    }
    if (filters.specialProductLengthTo) {
      queryString += "&specialProductLengthTo=" +
        filters.specialProductLengthTo;
    }

    if (filters.specialProductLengthFavnFrom) {
      queryString += "&specialProductLengthFavnFrom=" +
        filters.specialProductLengthFavnFrom;
    }
    if (filters.specialProductLengthFavnTo) {
      queryString += "&specialProductLengthFavnTo=" +
        filters.specialProductLengthFavnTo;
    }

    if (filters.specialProductWidthFrom) {
      queryString += "&specialProductWidthFrom=" +
        filters.specialProductWidthFrom;
    }
    if (filters.specialProductWidthTo) {
      queryString += "&specialProductWidthTo=" + filters.specialProductWidthTo;
    }

    if (filters.specialProductWidthFavnFrom) {
      queryString += "&specialProductWidthFavnFrom=" +
        filters.specialProductWidthFavnFrom;
    }
    if (filters.specialProductWidthFavnTo) {
      queryString += "&specialProductWidthFavnTo=" +
        filters.specialProductWidthFavnTo;
    }

    if (filters.specialProductOmfarFrom) {
      queryString += "&specialProductOmfarFrom=" +
        filters.specialProductOmfarFrom;
    }
    if (filters.specialProductOmfarTo) {
      queryString += "&specialProductOmfarTo=" + filters.specialProductOmfarTo;
    }

    if (
      filters.showSpecialProducts !== null &&
      filters.showSpecialProducts !== undefined
    ) {
      queryString += "&specialProductsOnly=" + filters.showSpecialProducts;
    }
    if (filters.industryId) {
      queryString += "&industryId=" + filters.industryId;
    }

    if (filters.searchText) {
      queryString += "&searchText=" + filters.searchText;
    }

    if (typeof filters.showCombiNet === "boolean") {
      queryString += "&showCombiNet=" + filters.showCombiNet;
    }

    if (typeof filters.showCollect === "boolean") {
      queryString += "&showCollect=" + filters.showCollect;
    }

    queryString += "&locale=" + this.getLocale();

    return queryString;
  }

  getAllNetFiles(netId: number) {
    return this.httpClient.exec<FileAttachmentModels.FileAttachment[]>(
      Models.Net.ApiUrl + "/get-all-files-on-net/" + netId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
  }

  private getLocale(): string {
    let locale = this.i18n.getLocale();
    if (locale === "nb-NO") {
      locale = "nb";
    }

    return locale;
  }
}
